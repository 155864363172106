import { Router } from "next/router";
// import * as NProgress from "./nprogress";
import { PersistentStorage } from "./services/persistent-storage";
import routes from "./routes";

Router.events.on("routeChangeStart", path => {
	if (path.indexOf(routes.purchaseBase) !== 0) {
		PersistentStorage().billingInfo.clear();
	}

	// NProgress.start("*");
});

Router.events.on("routeChangeError", () => {
	// NProgress.end("*");
});

Router.events.on("routeChangeComplete", path => {
	global.scrollTo(0, 0);
	// NProgress.end("*");

	if (path !== routes.logout) {
		const isAction = path.includes("action=");
		if (!isAction) {
			global.dataLayer.push({ event: "Returned Page" });
		}
	}
});
